import React from "react";
import FAQSection from "../../../Common/FAQSection/FAQSection";

export const designFAQList = [
    {
        title: "What are UI UX design services?",
        iconPath: [null],
        description: (
            <>
                UI (User Interface) UX (User Experience) design services are
                services provided by designers and developers to create visually
                appealing, easy-to-use, and user-friendly interfaces for digital
                products, such as websites and mobile applications. UI/UX design
                services aim to improve the overall experience that users have
                while interacting with a digital product, by optimizing the
                design and functionality of the interface to make it more
                intuitive, efficient, and enjoyable to use.
            </>
        ),
    },
    {
        title: "Why are UI UX services important?",
        iconPath: [null],
        description: (
            <>
                UI UX services are important because they help to improve the
                overall user experience of digital products, which can lead to
                increased user satisfaction, engagement, and loyalty. Projects
                with thought-through UI/UX design are proven to have increased
                conversions, higher retention rates, and ultimately, greater
                business success.
            </>
        ),
    },
    {
        title: "What are the benefits of UX design services?",
        iconPath: [null],
        description: (
            <>
                The benefits of UX design services include:
                <br />
                Improved user satisfaction and engagement
                <br />
                - Increased efficiency and productivity
                <br />
                - Higher conversion rates and sales
                <br />
                - Greater brand loyalty and customer retention
                <br />
                - Reduced development costs and time-to-market
                <br />
                - Better understanding of user needs and behaviors
                <br />
                - Enhanced credibility and trustworthiness
                <br />- Competitive advantage in the marketplace.
            </>
        ),
    },
    {
        title: "What is UI UX consulting?",
        iconPath: [null],
        description: (
            <>
                UI UX consulting is a service provided by experienced designers
                who offer advice and guidance to businesses on how to improve
                the user interface and user experience of their digital
                products. This may involve conducting user research, identifying
                areas for improvement, developing design solutions, and
                providing ongoing support and feedback to ensure the product
                meets user needs and business goals. The goal of UI UX
                consulting is to help businesses create more effective and
                user-friendly digital products that can increase user
                engagement, satisfaction, and ultimately, business success.
            </>
        ),
    },
    {
        title: "How much should I pay a UI UX designer?",
        iconPath: [null],
        description: (
            <>
                The prices for UI/UX design services can vary depending on
                various factors such as designer&apos;s experience, location, project
                scope, and complexity. Generally, the average hourly rate for a
                UI UX designer ranges from $75 to $150 per hour. However, some
                designers may charge a flat project fee, which can range from a
                few thousand dollars to tens of thousands of dollars or more
                depending on the project requirements. It&apos;s essential to discuss
                your specific project needs and budget with the designer to
                determine the best payment structure and pricing that works for
                both parties.
            </>
        ),
    },
];

function DesignFAQSection() {
    return <FAQSection title="FAQ" FAQList={designFAQList} />;
}

export default DesignFAQSection;
